import { FunctionComponent } from 'react';
import Pixie from '@business/pixies/Pixie';
import SmoothImage from '@xFrame4/components/common/SmoothImage';
import ALink from '@xFrame4/components/next/ALink';
import config from '@config';

interface PixieThumbnailProps
{
    pixie: Pixie;
    language: string;
    enableLazyLoading?: boolean;
}

/**
 * Renders a pixie thumbnail.
 * 
 * @param props 
 */
const PixieThumbnail: FunctionComponent<PixieThumbnailProps> = (props) =>
{
    let translation = props.pixie.getTranslation(props.language);
    let pixieUrl = props.pixie.getUrl(props.language).replace(config.appUrl, '');
    
    /** Render */
    return (
        <div className="pixie-thumbnail col-md-4 mb-4">
            <div className="mb-4">
                <ALink href={'/' + pixieUrl}>
                    <SmoothImage
                        enableLazyLoading={props.enableLazyLoading}
                        src={props.pixie.thumbnail.url}
                        className="thumbnail-image img-fluid cursor-pointer mb-1"
                        width={props.pixie.thumbnail.width}
                        height={props.pixie.thumbnail.height}
                        alt={translation?.title as string}
                    />
                </ALink>
                <div className="title cursor-pointer font-size-2 font-size-lg-1">{translation?.title}</div>
            </div>
        </div>
    );
}

PixieThumbnail.defaultProps = {
    enableLazyLoading: false
}

export default PixieThumbnail;