import { parse } from 'path';
import BusinessEntity, { BusinessEntityField, EntityManager, EntityManagerParameters } from './BusinessEntity';
import { BusinessEntityFieldType } from './Constants';

export class EntityTranslationManager<BT extends BusinessEntityTranslation> extends EntityManager<BT>
{
    constructor(parameters: EntityManagerParameters<BT>)
    {
        super({
            name: parameters.name,
            createEntity: parameters.createEntity,
            fields: [
                { name: 'language', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias
        });
    }
}

export class BusinessEntityTranslation extends BusinessEntity
{
    language!: string;
}

export class BusinessEntityWithTranslation<BT extends BusinessEntityTranslation> extends BusinessEntity
{
    /** The primary translation language. (e.g. "en") */
    primaryLanguage!: string;
    /** The name of the foreign key in the translation model (e.g. "pixie") */
    translationForeignKeyName!: string;
    /** The translation objects for each translation language. */
    translations: BT[] = [];

    /** Get the translation object for the primary translation language. */
    get primaryTranslation()
    {
        return this.getTranslation(this.primaryLanguage);
    }

    /**
     * Get the translation object for a language.
     * 
     * @param language The language code.
     */
    getTranslation(language: string)
    {
        return this.translations.find(et => et.language == language);
    }

    /**
     * Get the value of a translated field.
     * 
     * @param field The field name
     * @param language The translation language.
     * @returns The value of the translated field.
     */
    getField(field: string, language: string)
    {
        let translation = this.translations.find(et => et.language == language);
        if (translation !== undefined)
        {
            return translation[field];
        }
        else
        {
            return undefined;
        }
    }

    /**
     * Save the entity translation for a language. Saves the entity too.
     * 
     * @param language The language code.
     * @param translation The translation object.
     */
    async saveTranslation(language: string, translation: BusinessEntityTranslation)
    {
        // Save the entity first
        if (this.id == null && this.primaryLanguage == null) this.primaryLanguage = language;
        await this.save();

        // Save the translation
        translation[this.translationForeignKeyName] = this;
        translation.language = language;
        
        return await translation.save();
    }
}

export class EntityWithTranslationManager<BT extends BusinessEntityTranslation, B extends BusinessEntityWithTranslation<BT>> extends EntityManager<B>
{
    constructor(parameters: EntityWithTranslationManagerParameters<BT, B>)
    {
        super({
            name: parameters.name,
            createEntity: parameters.createEntity,
            fields: [
                { name: 'primaryLanguage', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
                { name: 'translations', type: BusinessEntityFieldType.Custom, isInput: false },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias
        });

        // Add the custom GraphQl fields for the translations
        let translationsField = this.fields.find(f => f.name == 'translations') as BusinessEntityField;
        translationsField.customGraphQl = `id language ${parameters.translationsCustomGraphQlFields}`;
    }

    // Override: parse the translation ID as a number
    copyFromGraphQL(entity: B, graphQlObject: any)
    {
        super.copyFromGraphQL(entity, graphQlObject);

        // parse the translation ID as a number
        if (entity.translations && entity.translations.length > 0)
        {
            entity.translations.forEach(et => et.id = parseInt(et.id as any));
        }
    }
}

export interface EntityWithTranslationManagerParameters<BT extends BusinessEntityTranslation, B extends BusinessEntityWithTranslation<BT>> extends EntityManagerParameters<B> 
{
    /** The translated fields. */
    translationsCustomGraphQlFields: string;
}

export default BusinessEntityWithTranslation;